<template>
  <div>
    <div class="waterfall">
      <div class="list"
        v-for="item in ServiceList"
        :key="item.SPId">
        <img :src="item.ThemeImgUrl"
          onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
          style="width:100%;"
          @click="rockDetail(item.SPId)" />
        <div style="width:90%;margin:auto;">
          <div style="font-size: 18px;color: #262626;font-weight:550;"
            class="van-ellipsis">{{item.SGNames}}</div>
          <div class="address">
            <div style="margin:3px 0;">
              <span class="tagClass">{{item.DemandLevelDesc}}</span>
              <!-- <span class="tagClass"
                  v-if="item.SerDuration != null && item.SerDuration != ''">{{item.SerDuration}}</span> -->
            </div>
          </div>
          <div class="count van-multi-ellipsis--l3"
            v-if="item.SerDuration != null && item.SerDuration != ''">服务时长：{{item.SerDuration}}</div>
          <div class="money">¥{{item.Cost}}
            <van-button icon="plus"
              size="small"
              style="border-radius: 12px;"
              color="#ffc002"
              type="primary"
              @click="orderPlace(item)">
            </van-button>
          </div>

          <br>
        </div>

      </div>
    </div>
    <van-action-sheet v-model="orderPlaceshow"
      title="已选商品">
      <div class="content">
        <div>
          <div v-for="item in orderplaceList"
            :key="item.SId">
            <div class="orderYx">
              <div style="float:left;width: 25%;">
                <img :src="item.SImgUrl"
                  style="width:100%;"
                  class="van-image__img"
                  onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'" />
              </div>

              <div class="orderRight">
                <span style="line-height:25px;">
                  <span style="font-size: 18px;font-weight: 600;"
                    class="van-ellipsis--l1">{{item.SName}}<br /></span>
                  <!-- <span style="font-size: 16px;font-weight: 600;">{{item.SName}}<br /></span> -->
                  <span style="color: red"> <span style="font-size:16px;">￥{{item.SPrice}}</span> {{item.SMUnit}}<br /></span>
                  <span>总价：￥{{item.SAmount}}<br /></span>
                </span>

              </div>
              <!-- <div style="float: left;width: 60%;margin: 5px;font-size: 16px;color: rgb(100, 101, 102);"
                class="van-multi-ellipsis--l2">{{item.SName}}</div> -->
              <div style="padding: 10px;margin-top: -30px;width: 26%;float: right;">
                <van-stepper v-model="item.SNum"
                  theme="round"
                  min="0"
                  style="right: 20px;top: 110px;"
                  button-size="22"
                  disable-input
                  @plus="changeAdd(item)"
                  @minus="changeDelet(item)"
                  @change="change" />
              </div>

            </div>

          </div>
        </div>
        <!-- <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()">
              下单
            </van-button>
          </div>
        </div> -->
        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="orderRen()">
              下 单
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenshow"
      title="配送老人"
      @close='close()'>
      <div class="content">

        <van-radio-group v-model="oldManList"
          v-for="item in MyBindInfoList"
          :key="item.BindId"
          style="margin: 10px;padding:15px 10px;background: #f6f7f8;border-radius: 5px;">
          <van-radio :name="item.BindId"
            checked-color="#ffc002"
            style="padding: 20rpx;">
            <div style="font-size: 18px;font-weight: 600;margin-bottom: 10rpx;margin: 5px;">
              {{item.OMName}}
              <span style="font-weight: 500;">{{item.OMMobile}}</span>
              <span style="padding: 0 10px;"
                @click="updata(item)">
                <van-icon name="edit" /></span>
              <span style="padding: 0 10px;"
                @click="add()">
                <van-icon name="add-o" /></span>
            </div>
            <!-- <div style="font-size: 14px;margin-bottom: 10rpx;">{{item.Summary}}</div> -->
            <div style="margin: 5px;">{{item.OMAddr}}</div>
          </van-radio>
        </van-radio-group>
        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()">
              确定
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenAddshow"
      title="配送老人信息"
      @close='close()'>
      <div class="content">
        <van-field v-model="oldmanForm.OMIdCard"
          class="shuru"
          name="oldmanForm.OMIdCard"
          label="身份证号"
          placeholder="请输入身份证号" />
        <van-field v-model="oldmanForm.OMMobile"
          class="shuru"
          name="oldmanForm.OMMobile"
          label="联系电话"
          placeholder="请输入老人联系电话" />
        <van-field v-model="oldmanForm.OMAddr"
          type="textarea"
          rows="1"
          autosize
          class="shuru"
          name="oldmanForm.OMAddr"
          label="居住地址"
          placeholder="请输入老人现居住地址" />
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            block
            type="info"
            color="black"
            @click="submit()">保存</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { setOpenId, getOpenId, setOrderId, getOrderId, removeOrderId } from "@/utils/auth";
import {
  WeGetServiceList,
  WeGetSerPackageList,
  WeGenerateOrder,
  WeSaveOrderPackage,
  WeGetOrderServiceList,
  WeDeleteOrderService,
  WePlaceOrder,
  WeGetMyRegInfo,
  WeGetMyBindInfoPage,
  WeSaveBindInfo,
} from "@/api/ylOrder.js";
import Vue from 'vue';
import { Toast } from 'vant';
import wx from "weixin-js-sdk";

Vue.use(Toast);
export default {
  data () {
    return {
      ServiceList: [],
      orderplaceList: [],
      MyBindInfoList: [],
      orderPlaceshow: false,
      orderRenshow: false,
      orderRenAddshow: false,
      oldmanForm: {
        OMIdCard: '',
        OMMobile: '',
        OMAddr: '',
        BindId: undefined
      },
      orderId: "",
      oldManList: "",
      sum: "",
      count: 1,
      sumid: "",
      addOrder: "",
    }
  },
  created () {
    this.WeGetProServicet()
    this.createOrder()
  },
  methods: {
    // 生成订单
    createOrder: function () {
      WeGenerateOrder({
        OType: 2,
        OSerKind: 0,
        OpenID: getOpenId(),
        OrgCode: this.$route.params.OrgCode,
        SOId: this.$route.params.Id,
      }).then((res) => {
        if (res.data.code == "0") {
          this.orderId = res.data.data
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 跳转详情
    rockDetail: function (SPId) {
      var path = '/ylOrder/ylOrder/orderData/' + SPId + '/' + this.$route.params.OrgCode
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
      // this.$router.push("/ylOrder/ylOrder/orderData/" + row);
    },
    WeGetProServicet: function (row) {
      WeGetSerPackageList({ soId: this.$route.params.Id }).then((res) => {
        this.ServiceList = res.data.data;
      });
    },
    getMyBindInfoPage: function () {
      WeGetMyBindInfoPage({ openID: getOpenId(), page: '1', limit: '999' }).then((res) => {
        if (res.data.code == '0') {
          if (res.data.data.length != '0') {
            this.MyBindInfoList = res.data.data;
            // this.orderListShow = false
          } else {
            this.MyBindInfoList = []
            this.orderRenshow = false
            this.orderRenAddshow = true

            // Toast.fail('未绑定老人信息');
            // this.orderListShow = true
          }
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    onAdd: function (row) {
      console.log(row);
    },
    close: function () {
      // console.log(123123);
      this.oldManList = ''
      this.oldmanForm.OMIdCard = ''
      this.oldmanForm.OMMobile = ''
      this.oldmanForm.OMAddr = ''
    },
    // 下单人
    orderRen: function (row) {
      this.orderRenshow = true
      this.getMyBindInfoPage()
    },
    // 下单弹窗
    orderPlace: function (row) {
      this.orderPlaceshow = true
      if (this.sumid != row.SPId) {
        this.count = 1
        this.sum = 1;
      }
      if (this.orderId != undefined && this.orderId != '') {
        this.saveOrderService(row.SPId);
      } else {

      }

    },
    //保存订单
    saveOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: row,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: this.sum,
      }).then((res) => {
        if (res.data.code == "0") {
          this.orderPlaceshow = true
          this.getOrderServiceList()
        } else if (res.data.code == "2") {
          console.log(123123);
          this.orderPlaceshow = true
          removeOrderId()
          // this.getOrderServiceList()
        }
        this.getOrderServiceList()
      });
    },
    //已下订单列表
    getOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: this.orderId }).then(
        (res) => {
          if (res.data.code == '0') {
            this.orderplaceList = res.data.data;
          } else {
            Toast.fail(res.data.msg);
          }
          // this.addOrder = res.data.data[0].OrderId
        }
      );
    },
    //添加数量
    changeAdd: function (row) {
      console.log(row);
      // this.OrderId = row.OrderId
      this.SId = row.SId
    },
    //删除数量
    changeDelet: function (row) {
      // this.OrderId = row.OrderId
      this.OSId = row.OSId
      this.SId = row.SId
    },
    //数量
    change: function (value) {
      console.log(value);
      if (value == '0') {
        this.deleteOrderService()
      } else {

        this.changeOrderService(value)
      }

    },
    // 添加删除后保存
    changeOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: this.SId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: row,
      }).then((res) => {
        if (res.data.code == "0") {
          console.log(res.data.data);
          this.changeOrderServiceList()
        }
      });
    },
    // 删除订单服务项目
    deleteOrderService: function () {
      WeDeleteOrderService({
        OSId: this.OSId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          Toast.fail('已删除');
          this.changeOrderServiceList()
          // this.orderPlaceshow = false;
        }
      });
    },
    changeOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: this.orderId }).then(
        (res) => {
          if (res.data.data.length != '0') {
            this.orderplaceList = res.data.data;
            this.addOrder = res.data.data[0].OrderId
          } else {
            this.show = false;
            this.orderPlaceshow = false;
          }

        }
      );
    },
    //下单
    placeOrder: function () {
      console.log(this.oldManList);
      if (this.oldManList == '') {
        Toast.fail('请选择需要配送的老人信息');
      } else {
        WePlaceOrder({ OpenID: getOpenId(), OrderId: this.orderId, BindId: this.oldManList }).then(
          (res) => {
            if (res.data.code == '0') {
              Toast.success('下单成功');
              // removeOrderId()
              this.count = ''
              this.sum = ''
              this.sumid = ''
              this.orderId = ''
              this.orderplaceList = []
              this.show = false;
              this.orderPlaceshow = false;
              this.orderRenshow = false
              this.createOrder()
            } else {
              Toast.fail(res.data.msg);
            }
          }
        );
      }
    },
    // 添加老人信息
    submit () {
      console.log(this.oldmanForm);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(this.oldmanForm.OMIdCard)) {
        Toast.fail("身份证号码不正确");
      } else if (this.oldmanForm.OMMobile == '') {
        Toast.fail("请输入老人联系电话");
      } else if (this.oldmanForm.OMAddr == '') {
        Toast.fail("请输入老人现居住地址");
      } else {
        WeSaveBindInfo(
          {
            OpenID: getOpenId(),
            OMIdCard: this.oldmanForm.OMIdCard,
            OMMobile: this.oldmanForm.OMMobile,
            OMAddr: this.oldmanForm.OMAddr,
            BindId: this.oldmanForm.BindId,
          }
        ).then((res) => {
          if (res.data.code == '0') {
            Toast.success('绑定成功');
            this.orderRenAddshow = false
            this.orderRenshow = true
            this.oldmanForm.OMIdCard = ''
            this.oldmanForm.OMMobile = ''
            this.oldmanForm.OMAddr = ''
            this.oldmanForm.BindId = undefined
            this.getMyBindInfoPage()
          } else {
            Toast.fail(res.data.msg);
          }

        });
      }
    },
    add: function () {
      this.orderRenAddshow = true
    },
    // 编辑老人信息
    updata: function (row) {
      this.orderRenAddshow = true
      console.log(row);
      this.oldmanForm = Object.assign({}, row);
    },
  },
}
</script>

<style scoped>
.waterfall {
  /* width: 95%; */
  margin: 5px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 10px 15px 10px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.tagClass {
  margin: 3px 3px 3px 0;
  padding: 2px;
  color: #fe5b47;
  border: 1px solid #ffa296;
  font-size: 14px;
  border-radius: 3px;
}
.grouptitle {
  border-left: 4px solid #ff8f1f;
  /* float: left; */
  padding-left: 5px;
  margin: 5px;
}
.money {
  color: #f2270c;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.van-action-sheet {
  height: 500px !important;
}
.van-action-sheet__content {
  margin-bottom: 60px !important;
}
/deep/.van-stepper--round .van-stepper__plus {
  background-color: #ffc002 !important;
}
/deep/.van-stepper--round .van-stepper__minus {
  background-color: #ffc002 !important;
  border: 1px solid #ffc002 !important;
  color: #fff !important;
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.orderYx {
  float: left;
  margin: 5px 10px;
  border-bottom: 1px solid #c6c6c6;
  width: 95%;
  padding-bottom: 5px;
}
.orderRight {
  float: left;
  margin-left: 15px;
  font-size: 14px;
  color: #646566;
  width: 70%;
}
</style>